import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const myClubTips = () => {
  return (
    <Layout>
      <SEO
        title="PES myClub Tips and Tricks"
        description="Here is a collection of tips and tricks to get the most out of PES 2021 myClub and avoid common mistakes."
      />
      <div className="container">
        <h1>PES myClub Tips and Tricks</h1>
        <p style={{ paddingTop: "1rem" }}>
          <small>
            If you are specifically looking for ways to get more GP,{" "}
            <Link to="/how-to-get-more-gp/">visit this section instead</Link>.
          </small>
        </p>
        <p>
          Here is a collection of tips to get the most out of PES 2021 myClub.
        </p>
        <ol>
          <li>
            Save your coins! Use them to get weekly Featured Players/Teams or to
            unlock a Top Manager at the beginning. For Contract Renewals and
            spinning Agents always use GP (unless you’re fine with injecting
            real money into the game).
          </li>
          <li>
            When you’re just getting started, play the "Introductory Online
            Challenge" competition first (inside WHAT'S ON), where each of your
            first 5 games will give you a boost of 25.000 GP (125.000 GP in
            total).
          </li>
          <li>
            If you’re playing a Ranked Match (SIM) to farm GP, immediately
            toggle “Match Data” to skip the game’s cut scenes. Matches will end
            quicker.
          </li>
          <li>
            When training players, keep in mind that trainers that reached
            levels multiple of 10 (level 10/20/30/40/…) will give more base EXP.
            Use{" "}
            <a
              href="https://www.filedropper.com/baseexperiencecalculator"
              target="_blank"
              rel="noopener noreferrer"
            >
              this calculator
            </a>{" "}
            to know exactly how much base EXP you can gain.
          </li>
          <li>
            Duplicate trainers provide a lot more EXP (3x base EXP) - if you
            have 2 exact same players, train one with the other for bigger
            boosts.
          </li>
          <li>
            If you have 3 exact same players, you can{" "}
            <Link to="/trading-players/">trade for any other player</Link> of
            the same Ball Type, level 1 (Legends do not apply). They all need to
            be the same version, ie, this won’t work if you have a Featured
            version of a player and 2 regular versions.
          </li>
          <li>
            Players who get a better match rating will get more EXP - and those
            are usually the ones that score goals. If you want to develop your
            CBs, consider using them as strikers in easy VS COM games for more
            EXP after each match.
          </li>
          <li>
            Top Managers are locked with coins at the beginning - as you play
            more and more matches (including SIMs), they will gradually become
            unlocked with GP. 400 games should unlock all managers in myClub.{" "}
            <Link to="/managers/">
              Have a look at some of the managers in the game here
            </Link>
            .
          </li>
          <li>
            You can use Management Skill Boost items to increase your Manager’s
            Management skills (so that more skilled players can play in your
            team at the same time).
          </li>
          <li>
            Not every Collector’s Box has the same odds of giving you a Black
            Ball. Some weeks you’re better off saving GP.
          </li>
          <li>
            Try to login every day for free rewards - if possible, play one game
            a day to get your “First Victory of the Day” (3.000 GP). This will
            work even in an easy VS COM game.
          </li>
          <li>
            Make sure you combine scouts to increase your chances of getting the
            players you want. You can access{" "}
            <a
              href="http://pesdb.net/pes2021/"
              target="_blank"
              rel="noopener noreferrer"
            >
              PES DB
            </a>{" "}
            to know the scout combinations you need, or use{" "}
            <a
              href="https://docs.google.com/spreadsheets/d/1lSj0B0grwqohhia4XaB97xfknZ6zKEzuh1r54427FEs/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              this spreadsheet
            </a>{" "}
            to know which 100% Free Scouts you can use to get Gold/Silver/Bronze
            players (make sure you click on "File > Make a Copy" first).
          </li>
          <li>
            Have a look at 50% chance scout combinations as well. Every now and
            then you will notice you have the other player - meaning that a 50%
            chance is actually 100%, since you can't get duplicate players with
            scouts!
          </li>
          <li>
            Use cheap players as much as possible (eg: to win easy VS COM
            games). Save your superstars for the important matches.
          </li>
          <li>
            Don’t underestimate young players’ potential. Some silvers can
            actually reach 90+ OVR when maxed out.{" "}
            <Link to="/best-players-ball-type/">
              Here are some of my favorite Gold, Silver and Bronze players
            </Link>
            .
          </li>
          <li>
            Use your Contract Renewal Tickets on your most expensive players.
          </li>
          <li>
            Make sure you lock your favorite players so that you don't
            accidentally convert them into trainers. To do so, visit the CLUB
            HOUSE / myClub MEMBERS screen and press the "Player Menu" button for
            the "Lock" feature.
          </li>
          <li>
            To save Stamina Recovery Items, you can rest your main team by
            switching to a SIM game with your white balls every 2 or 3 games.
          </li>
          <li>
            You can register multiple squads to easily toggle between your
            3-star, 5-star and SIM teams. To do so, simply press the "Squad
            List" button in the CLUB HOUSE / SQUAD MANAGEMENT screen.
          </li>
          <li>
            To check your players' in-game stats (such as appearances, goals,
            assists, average ratings) go to the CLUB HOUSE / SQUAD MANAGEMENT
            screen, pick a player and press "Compare".
          </li>
          <li>
            Make sure your strongest team is the team assigned for Cloud
            Matches. This will be the team other players will face in SIM
            matches, and you will earn free GP once a week. The amount of GP
            will depend on your team's performance.
          </li>
          <li>
            Pay attention to{" "}
            <Link to="/player-form-condition-rating/">
              Player Form and Live Condition Rating
            </Link>
            : as your team grows bigger, try to pick only players that are on A
            and B-Condition, and in weeks where every player is on Condition
            Rating of "C", have a look at the "Form" attribute of each player.
          </li>
          <li>
            You can save some GP by renewing a player's contract while he is
            still developing. When you scout a player that you're sure you will
            be using often, I would suggest you immediately renew his contract
            while he is still level 1.
          </li>
        </ol>
      </div>
    </Layout>
  )
}

export default myClubTips
